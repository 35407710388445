
const api_key = 'pk.c13cb318b0813c7762c3320fc1e102f7';

export function supportsLocation() {
  return navigator.geolocation;
}

export function allowsLocation() {
  return new Promise((resolve, reject) => {
    navigator.permissions.query({ name: 'geolocation' })
    .then(permission => {
      if (permission.state === 'granted' || permission.state === 'prompt') {
        resolve(permission.state);
      } else if (permission.state === 'prompt') {
        reject(new Error('User denied location access'));
      }
    })
    .catch(error => {
      reject(error);
    });
  });
}

export function getLocation() {
  return new Promise((resolve, reject) => {
    if (supportsLocation()) {
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve(position.coords);
        },
        error => {
          reject(error);
        }
      );
    } else {
      reject(new Error('Geolocation is not supported'));
    }
  });
}

export function getLocationAndPlaceName () {
  return new Promise((resolve, reject) => {
    getLocation().then(coords => {
      if (coords) {
        getPlaceName(coords.latitude, coords.longitude)
        .then(data => {
          resolve(data);
        }).catch(error => {
          reject(error);
        });
      }
    }).catch(error => {
      reject(error);
    });
  });
}

export function getPlaceName (lat, lon) {
  return new Promise((resolve, reject) => {
    fetch(`https://eu1.locationiq.com/v1/reverse?${new URLSearchParams({
      'key': api_key,
      'lat': lat,
      'lon': lon,
      'format': 'json'
    })}`)
    .then(response => response.json())
    .then(data => {
      resolve(data);
    }).catch(error => {
      reject(error);
    });
  });
}

export function autoComplete (str) {
  return new Promise((resolve, reject) => {
    fetch(`https://eu1.locationiq.com/v1/autocomplete?${new URLSearchParams({
      'q': str,
      'viewbox': [
        // Top left (Denmark coordinates)
        57.84545039534199,
        7.695403774561619,
        // Bottom right (Denmark coordinates)
        54.52547362759797,
        12.75037458971817
      ].join(','),
      'key': api_key,
      'importancesort': '1',
      'normalizecity': '1',
      'tag': [
        'place:city',
        'place:borough',
        'place:suburb',
        'place:town',
        'place:village',
        'place:hamlet',
        'place:island',
      ].join(','),
      'accept-language': 'da',
      'format': 'json'
    })}`)
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        reject(new Error(data.error));
      } else {
        // Filter results to only include those with country_code 'DK'
        const filteredData = data
          .filter(place => place.address.country_code === 'dk')
          .map(place => {
            // Clean up display_name by removing postal codes, regions, etc.
            let cleanedName = place.display_name.split(',')[0]; // Keep only the first part (e.g., city name)
            return { ...place, display_name: cleanedName };
          });

        resolve(filteredData);
      }
    }).catch(error => {
      reject(error);
    });
  });
}

export default {
  supportsLocation, 
  allowsLocation, 
  getLocation, 
  getLocationAndPlaceName, 
  getPlaceName, 
  autoComplete
};