import icon_lookup from './YRIconLookup.js';
import { supportsLocation, allowsLocation, getLocationAndPlaceName, autoComplete } from './LocationUtils.js';
import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('weatherApp', () => ({
  location: '',
  lat: 55.4038,
  lon: 10.4024,
  placename: 'Odense',
  searchQuery: '',
  autoCompleteEntries: [],
  forecastDays: [],
  weatherData: {
    current: { temperature: 'N/A', rainProbability: 'N/A', rainAmount: 'N/A', windSpeed: 'N/A', windDirection: 0, weatherIcon: '', forecast: [] },
  },
  weatherShowMore: false,
  showRestOfDay: false,
  loading: true,

  init() {
    this.fetchWeatherData();
  },

  // Set location based on CMS input
  setLocationFromCMS(locationName) {
    if (!locationName) return;
    this.placename = locationName;
    this.fetchWeatherData();
  },

  // Fetch weather data based on location
async fetchWeatherData() {
  this.loading = true;
  
  try {
    const headers = {};

    if (typeof window === 'undefined') { 
      headers['User-Agent'] = 'TV 2 Fyn DK/1.0 (loko@tv2fyn.dk)';
    }

    // Fetch weather data
    const response = await fetch(`https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=${this.lat}&lon=${this.lon}`, {
      headers: headers
    });
    
    const data = await response.json();
    this.processWeatherData(data);
  } catch (error) {
    console.error('Error fetching weather data:', error);
  } finally {
    this.loading = false;
  }
},

  processWeatherData(data) {
    const timeseries = data.properties.timeseries;
    
    // Get current weather data for the first hour
    this.weatherData.current = {
        temperature: Math.round(timeseries[0].data.instant.details.air_temperature),
        rainProbability: timeseries[0].data.next_1_hours ? timeseries[0].data.next_1_hours.details.precipitation_amount : 0,
        rainAmount: timeseries[0].data.instant.details.precipitation_amount || 0,
        windSpeed: timeseries[0].data.instant.details.wind_speed,
        windDirection: timeseries[0].data.instant.details.wind_from_direction,
        weatherIcon: this.getWeatherIcon(timeseries[0])
    };
    
    // Store the forecast for the entire day (next 24 hours)
    const today = new Date().getDate();
    this.weatherData.forecast = timeseries.filter(ts => new Date(ts.time).getDate() === today).map(ts => ({
        time: new Date(ts.time).getHours(),
        temperature: Math.round(ts.data.instant.details.air_temperature),
        rainAmount: ts.data.next_1_hours ? ts.data.next_1_hours.details.precipitation_amount : 0,
        windSpeed: ts.data.instant.details.wind_speed,
        windDirection: ts.data.instant.details.wind_from_direction,
        weatherIcon: this.getWeatherIcon(ts)
    }));
},
  getWeatherIcon(ts) {
    if (ts.data.next_1_hours) {
      const symbolCode = ts.data.next_1_hours.summary.symbol_code;
      return `/weather_svgs/${icon_lookup(symbolCode)}.svg`;
    }
    return '/weather_svgs/default.svg';
  },

  // Autocomplete location search
  async performSearch() {
    if (this.searchQuery.length < 3) return;
    try {
      const data = await autoComplete(this.searchQuery);
      this.autoCompleteEntries = data;
    } catch (error) {
      console.error('Error in autocomplete:', error);
    }
  },

  // Set location from search result
  setLocationFromSearch(place) {
    this.lat = place.lat;
    this.lon = place.lon;
    this.placename = place.display_name;
    this.autoCompleteEntries = [];
    this.fetchWeatherData();
  },

  // Get location from device
  async fetchLocationFromDevice() {
    try {
      const data = await getLocationAndPlaceName();
      this.lat = data.lat;
      this.lon = data.lon;
      this.placename = data.address.city || data.address.town || data.address.village || data.address.neighbourhood || 'Ukendt sted';
      this.fetchWeatherData();
    } catch (error) {
      console.error('Error fetching location from device:', error);
    }
  },
}));
