import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";
import BeyondWords from '@beyondwords/player';


Alpine.data('beyondwords', ({ projectID, sourceID }) => ({
  projectID: parseInt(projectID, 10),
  sourceID: sourceID,
  state: 'not-started',
  player: null,

  init() {
    // console.log(this.projectID, this.sourceID);
    this.player = new BeyondWords.Player({
      projectId: this.projectID,
      sourceId: this.sourceID,
      clientSideEnabled: true,
      highlightSections: 'none'
    });
    this.player.addEventListener('<any>', this.update.bind(this));
  },

  play() {
    this.state = 'playing';
    this.player.playbackState = this.state;
  },
  pause() {
    this.state = 'paused';
    this.player.playbackState = this.state;
  },
  toggle() {
    if (this.state == 'playing') {
      this.state = 'paused';
    } else {
      this.state = 'playing';
    }
    this.player.playbackState = this.state;
  },

  update() {
    if (this.player.playbackState == 'playing' || this.player.playbackState == 'paused') {
      if (this.player.currentTime && this.player.duration) {
        const progress = this.player.currentTime / this.player.duration;
        this.$dispatch('beyond_words_update', { progress });
      }
    } else {
      this.$dispatch('beyond_words_update', { progress: 0 });
    }
    this.state = this.player.playbackState;
  }

}));
