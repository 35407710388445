import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('circle_progress', ({
  size,
  trackWidth,
  progressWidth,
  initialProgress
}) => ({
  size: size,
  trackWidth: trackWidth,
  progressWidth: progressWidth,
  progress: initialProgress,

  init() {
    this.innerSize = size - Math.max(this.trackWidth, this.progressWidth);
  },
  update(evt) {
    // console.log(evt);
    let progress = evt.detail.progress;
    if (Number.isFinite(progress)) {
      if (progress >= 0 && progress <= 1) {
        this.progress = progress;
      }
    }
  }
}));
